<template>
  <CustomBottomSheet
    :refName="'StudentHasDebtsReportFilter'"
    size="xl"
    :headerText="$t('StudentHasDebts.modelName')"
    :headerIcon="icon"
    @opened="getDialogs"
  >
    <div class="row">
      <CustomSelectBox
        :className="'col-md-12'"
        :id="`userToken`"
        :value="users.filterData.userToken"
        :options="userTokenOptions"
        v-on:changeValue="users.filterData.userToken = $event"
        :title="$t('students.select')"
        :imgName="'students.svg'"
      />

      <CustomInput
        :className="'col-md-12'"
        :id="`sendTo`"
        :value="users.filterData.sendTo"
        v-on:changeValue="users.filterData.sendTo = $event"
        :title="$t('Reports.sendTo')"
        :imgName="'email.svg'"
      />
    </div>

    <div class="form-actions">
      <div class="icon-submit" @click.prevent="getReport">
        <img
          src="@/assets/images/check-icon.svg"
          :title="$t('Reports.print')"
        />
      </div>
      <div
        @click.prevent="closeBottomSheet('StudentHasDebtsReportFilter')"
        class="icon-cancel"
      >
        <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
      </div>
    </div>
  </CustomBottomSheet>
</template>

<script>
import CustomBottomSheet from "@/components/general/CustomBottomSheet.vue";
import icon from "@/assets/images/cards.svg";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import generalMixin from "@/utils/generalMixin";
import { STATUS } from "@/utils/constants";
import Users from "@/models/users/Users";
import apiUser from "@/api/user";
import { getLanguage, viewFileFromServer } from "@/utils/functions";
import { getUsersDialog } from "@/utils/dialogsOfApi";
import { USER_TYPE } from "@/utils/constantLists";

export default {
  mixins: [generalMixin],
  data() {
    return {
      isLoading: false,
      language: getLanguage(),
      users: new Users("students"),
      icon,
      userTokenOptions: [],
    };
  },
  components: {
    CustomBottomSheet,
    CustomSelectBox,
    CustomInput,
  },
  props: {
    userToken: {
      type: String,
      default: "",
    },
  },
  methods: {
    async getDialogs() {
      this.users.setInitialValue("students");
      if (this.userToken) {
        this.users.filterData.userToken = this.userToken;
      }
      this.getStudentsDialog();
    },
    async getStudentsDialog() {
      this.$emit("isLoading", true);
      let params = {
        modelName: "students",
        userTypeToken: USER_TYPE.Student,
      };
      this.userTokenOptions = await getUsersDialog(params);
      this.$emit("isLoading", false);
    },

    async getReport() {
      this.$emit("isLoading", true);
      this.users.filterData.userTypeToken = USER_TYPE.Student;
      try {
        const response = await apiUser.getUsersFinanceReport(
          this.users.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.closeBottomSheet("StudentHasDebtsReportFilter");
          if (response.data.reportPath)
            viewFileFromServer(response.data.reportPath);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.$emit("isLoading", false);
    },
    async created() {},
  },
};
</script>
