<template>
  <CustomBottomSheet
    :refName="'JoiningApplicationEducationalFilterReport'"
    size="xl"
    :headerText="$t('JoiningApplicationEducationals.modelName')"
    :headerIcon="icon"
    @opened="
      joiningApplicationEducationals.filterData.setInitialValue();
      setFilter();
    "
  >
    <div class="row">
      <CustomInput
        :className="'col-md-12'"
        :id="`sendTo`"
        :value="joiningApplicationEducationals.filterData.sendTo"
        v-on:changeValue="
          joiningApplicationEducationals.filterData.sendTo = $event
        "
        :title="$t('Reports.sendTo')"
        :imgName="'email.svg'"
      />
      <CustomSelectBox
        :className="'col-md-12'"
        :id="`educationalType`"
        :value="joiningApplicationEducationals.filterData.educationalType"
        :options="educationalTypeOptions"
        v-on:changeValue="
          joiningApplicationEducationals.filterData.educationalType = $event
        "
        :title="$t('type')"
        :imgName="'type.svg'"
      />
      <CustomSelectBox
        :className="'col-md-12'"
        :id="`userStudentToken`"
        :value="joiningApplicationEducationals.filterData.userStudentToken"
        :options="userTokenOptions"
        v-on:changeValue="
          joiningApplicationEducationals.filterData.userStudentToken = $event;
          getEducationalJoiningApplicationsDialog();
        "
        :title="$t('students.select')"
        :imgName="'students.svg'"
      />
      <CustomSelectBox
        :className="'col-md-12'"
        :id="`educationalJoiningApplicationToken`"
        :value="
          joiningApplicationEducationals.filterData
            .educationalJoiningApplicationToken
        "
        :options="educationalJoiningApplicationTokenOptions"
        v-on:changeValue="
          joiningApplicationEducationals.filterData.educationalJoiningApplicationToken =
            $event
        "
        :title="$t('EducationalJoiningApplications.select')"
        :imgName="'EducationalJoiningApplications.svg'"
      />
      <CustomSelectBox
        :className="'col-md-12'"
        :id="`filter-educationalGroupToken`"
        :value="joiningApplicationEducationals.filterData.educationalGroupToken"
        :options="educationalGroupTokenOptions"
        v-on:changeValue="
          joiningApplicationEducationals.filterData.educationalGroupToken =
            $event
        "
        :title="$t('EducationalGroups.select')"
        :imgName="'EducationalGroups.svg'"
      />
      <BaseTree
        :hierarchySystemTypeFilterToken="hierarchySystemTypeFilterToken"
        :treeModel="getTreeModel"
        v-on:changeValue="baseTreeChanged($event)"
        :showParent="true"
        :filterParentDirect="true"
      />

      <template v-if="currentTab != 'postponed'">
        <DateTimePicker
          class="col-md-6"
          :id="`filter-freezedDateTimeFrom`"
          type="dateTime"
          :value="joiningApplicationEducationals.filterData.freezedDateTimeFrom"
          :title="$t('JoiningApplicationEducationals.freezedFrom')"
          v-on:changeValue="
            joiningApplicationEducationals.filterData.freezedDateTimeFrom =
              $event.dateTime
          "
          :language="language"
        />
        <DateTimePicker
          class="col-md-6"
          :id="`filter-freezedDateTimeTo`"
          type="dateTime"
          :value="joiningApplicationEducationals.filterData.freezedDateTimeTo"
          :title="$t('JoiningApplicationEducationals.freezedTo')"
          v-on:changeValue="
            joiningApplicationEducationals.filterData.freezedDateTimeTo =
              $event.dateTime
          "
          :language="language"
        />
      </template>
      <template v-if="currentTab != 'freezed'">
        <DateTimePicker
          class="col-md-6"
          :id="`filter-postponedDateTimeFrom`"
          type="dateTime"
          :value="
            joiningApplicationEducationals.filterData.postponedDateTimeFrom
          "
          :title="$t('JoiningApplicationEducationals.postponedFrom')"
          v-on:changeValue="
            joiningApplicationEducationals.filterData.postponedDateTimeFrom =
              $event.dateTime
          "
          :language="language"
        />
        <DateTimePicker
          class="col-md-6"
          :id="`filter-postponedDateTimeTo`"
          type="dateTime"
          :value="joiningApplicationEducationals.filterData.postponedDateTimeTo"
          :title="$t('JoiningApplicationEducationals.postponedTo')"
          v-on:changeValue="
            joiningApplicationEducationals.filterData.postponedDateTimeTo =
              $event.dateTime
          "
          :language="language"
        />
      </template>

      <DateTimePicker
        class="col-md-6"
        :id="`filter-joiningDateTimeFrom`"
        type="dateTime"
        :value="joiningApplicationEducationals.filterData.joiningDateTimeFrom"
        :title="$t('JoiningApplicationEducationals.joiningFrom')"
        v-on:changeValue="
          joiningApplicationEducationals.filterData.joiningDateTimeFrom =
            $event.dateTime
        "
        :language="language"
      />
      <DateTimePicker
        class="col-md-6"
        :id="`filter-joiningDateTimeTo`"
        type="dateTime"
        :value="joiningApplicationEducationals.filterData.joiningDateTimeTo"
        :title="$t('JoiningApplicationEducationals.joiningTo')"
        v-on:changeValue="
          joiningApplicationEducationals.filterData.joiningDateTimeTo =
            $event.dateTime
        "
        :language="language"
      />
    </div>

    <div class="form-actions">
      <div class="icon-submit" @click.prevent="getReport">
        <img
          src="@/assets/images/check-icon.svg"
          :title="$t('Reports.print')"
        />
      </div>
      <div
        @click.prevent="
          closeBottomSheet('JoiningApplicationEducationalFilterReport')
        "
        class="icon-cancel"
      >
        <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
      </div>
    </div>
  </CustomBottomSheet>
</template>

<script>
import CustomBottomSheet from "@/components/general/CustomBottomSheet.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import generalMixin from "@/utils/generalMixin";
import { STATUS } from "@/utils/constants";
import JoiningApplicationEducationals from "@/models/educational/joiningApplicationEducationals/JoiningApplicationEducationals";
import apiJoiningApplicationEducational from "@/api/educational/joiningApplicationEducationals";
import { getLanguage, viewFileFromServer } from "@/utils/functions";
import BaseTree from "@/components/general/BaseTree.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import DateTimePicker from "@/components/general/DateTimePicker.vue";
import { getEducationalGroupsDialog } from "@/utils/dialogsOfApi";
import {
  USER_TYPE,
  SYSTEM_TYPE,
  EDUCATIONAl_TYPE,
} from "@/utils/constantLists";
import {
  getUsersDialog,
  getEducationalJoiningApplicationsDialog,
} from "@/utils/dialogsOfApi";
import icon from "@/assets/images/filter.svg";
import imgApproval from "@/assets/images/accepted.svg";
import imgRejected from "@/assets/images/rejected.svg";
import imgCanceled from "@/assets/images/cancel.svg";

export default {
  mixins: [generalMixin],
  components: {
    CustomBottomSheet,
    CustomInput,
    BaseTree,
    CustomSelectBox,
    DateTimePicker,
  },

  props: {
    currentTab: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      joiningApplicationEducationals: new JoiningApplicationEducationals(),
      icon,
      language: getLanguage(),
      userTokenOptions: [],
      educationalJoiningApplicationTokenOptions: [],
      educationalGroupTokenOptions: [],
      educationalTypeOptions: [],
    };
  },
  watch: {},
  computed: {
    hierarchySystemTypeFilterToken() {
      return SYSTEM_TYPE.EducationalActivity;
    },
    getTreeModel() {
      let treeModelObject = {
        systemComponentHierarchyToken:
          this.joiningApplicationEducationals.filterData
            .systemComponentHierarchyToken,
        hierarchyTitle: this.$t(
          "SystemComponentsHierarchies.selectEducationalActivity"
        ),
        systemComponentToken:
          this.joiningApplicationEducationals.filterData.systemComponentToken,
        componentTitle: this.$t("SystemComponents.selectEducationalActivity"),
        parentToken:
          this.joiningApplicationEducationals.filterData
            .educationalCategoryToken,
        parentTitle: this.$t("EducationalCategories.selectParent"),
        parentImgName: "EducationalCategories.svg",
      };
      return treeModelObject;
    },
  },
  methods: {
    //#region dialogs
    async getDialogs() {
      this.educationalTypeDialog();
      this.getStudentsDialog();
      this.getEducationalGroupsDialog();
      this.getEducationalJoiningApplicationsDialog();
    },

    async setFilter() {
      this.getDialogs();
    },
    baseTreeChanged(data) {
      if (data.systemTypeToken == SYSTEM_TYPE.EducationalActivity) {
        this.joiningApplicationEducationals.filterData.systemComponentHierarchyToken =
          data.hierarchyToken;
        this.joiningApplicationEducationals.filterData.systemComponentToken =
          data.componentToken;
        this.joiningApplicationEducationals.filterData.educationalCategoryToken =
          data.parentToken;
      }
    },
    async educationalTypeDialog() {
      this.$emit("isLoading", true);
      let list = [];
      list.push({
        value: EDUCATIONAl_TYPE.IsFreezed,
        text: this.$t("JoiningApplicationEducationals.tabs.freezed"),
        image: imgRejected,
      });
      list.push({
        value: EDUCATIONAl_TYPE.IsPostponed,
        text: this.$t("JoiningApplicationEducationals.tabs.postponed"),
        image: imgCanceled,
      });
      list.push({
        value: EDUCATIONAl_TYPE.RelatedWithGroup,
        text: this.$t("JoiningApplicationEducationals.tabs.related"),
        image: imgApproval,
      });

      this.educationalTypeOptions = list;
      this.$emit("isLoading", false);
    },
    async getStudentsDialog() {
      this.$emit("isLoading", true);
      let params = {
        modelName: "students",
        userTypeToken: USER_TYPE.Student,
      };
      this.userTokenOptions = await getUsersDialog(params);
      this.$emit("isLoading", false);
    },
    async getEducationalJoiningApplicationsDialog() {
      this.$emit("isLoading", true);
      let params = {
        userStudentToken:
          this.joiningApplicationEducationals.filterData.userStudentToken,
      };
      this.educationalJoiningApplicationTokenOptions =
        await getEducationalJoiningApplicationsDialog(params);
      this.$emit("isLoading", false);
    },

    async getEducationalGroupsDialog() {
      this.$emit("isLoading", true);
      this.educationalGroupTokenOptions = await getEducationalGroupsDialog();
      this.$emit("isLoading", false);
    },

    async getReport() {
      this.$emit("isLoading", true);
      try {
        const response = await apiJoiningApplicationEducational.getReport(
          this.joiningApplicationEducationals.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.closeBottomSheet("JoiningApplicationEducationalFilterReport");
          viewFileFromServer(response.data.reportPath);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.$emit("isLoading", false);
    },
    async created() {},
  },
};
</script>
